<template>
  <main class="main">
    <Jumbo v-if="jumbo" v-bind="jumbo" backdrop-custom="linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)"/>
    <section class="steps">
      <div class="container">
        <div class="column" 
            v-for="(step, index) in steps" 
            :key="index" 
            :style="`background-image: url(${step.image})`">
          <div class="backdrop"/>
          <h2 class="column-title">{{ step.title }}</h2>
          <h3 class="column-subtitle">{{ step.subtitle }}</h3>
          <ul class="column-list">
            <li v-for="(item, index) in getListItems(step.step)" :key="index" v-html="item"/>
          </ul>
          <router-link class="column-link" :to="setLink(step.step)">
            {{ step.button }}
          </router-link>
          <span class="column-step"> {{ step.step }}</span>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Jumbo from "@/components/Jumbo.vue"
import repondre from '../../../public/json/decret-tertiaire-repondre.json'

export default {
  name: "Repondre",
  components: {
    Jumbo
  },
  props: {
    payload: {
      type: Array,
    }
  },
  metaInfo: {
    meta: [{
      name: 'description',
      content: 'Découvrez l’offre globale de Deepki et comment nous pouvons vous accompagner dans votre stratégie de réponse au décret tertiaire.'
    }]
  },
  methods: {
    getListItems(step) {
      const list = this.steps.find(f => f.step === step)
      let newList = []
      Object.keys(list).filter(f => f.match(/list_el/)).forEach(f => {
        list[f] ? newList.push(list[f]) : ''
      })
      return newList
    },
    setLink(step) {
      if (step === "A") {
        return '/definissez-votre-strategie'
      } else if (step === "B") {
        return '/creez-votre-plan-d-action'
      } else {
        return '/atteignez-vos-objectifs'
      }
    }
  },
  computed: {
    jumbo() {
      return this.data.find(f => f.type === 'jumbo')
    },
    steps() {
      return this.data.filter(f => f.type === 'step')
    },
    data() {
      return repondre
    } 
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.steps {
  background-color: $black;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  margin-top: -24px;

  @media(max-width: 1180px) {
    flex-wrap: wrap;
    margin-top: 0;
  }
}

.container {
  display: flex;
  flex-direction: row;
  margin-bottom: 64px;

  @media(max-width: 1180px) {
    flex-wrap: wrap;
  }
}

.column {
  position:relative;
  width: 33%;
  margin: 0 24px;
  padding: 32px;
  line-height: 1.2;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 1180px) {
    width: 100%;
    margin-bottom: 24px;
  }

  @media(max-width: 768px) {
    padding: 32px 12px;
  }

  &-title {
    font-size: 32px;
    font-weight: 900;
    padding-bottom: 32px;
    text-align: center;
  }

  &-subtitle {
    font-size: 24px;    
    text-align: left;
  }

  &-list {
    list-style: inherit;
    margin-left: 24px;
    margin-bottom: 64px;

    li {
      padding: 24px 12px 0 0;
      font-size: 18px;
    }
  }

  &-step {
    position: absolute;
    bottom: -20px;
    left: -30px;
    z-index: -1;
    color: rgba(#3B3D3E, .5);  
    font-size: 14rem;
    font-weight: bold;
    line-height: .8;
  }

  &-link {
    position: absolute;
    bottom: 24px;
    display: flex;
    width: calc(100% - 64px);
    justify-content: space-between;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    color: #3B3D3E;
    font-weight: bold;
    padding: 6px 0;

    &:hover {
      color: $green;

      &::after {
        transform: translateX(2px);
      }
    }

    &::after {
      content: ">";
      transition: all .1s ease-in;
      font-size: 32px;
      line-height: .6;
      color: $green;
      font-weight: bold;
    }
  }

  .backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #FFF;
  }
}

</style>

